import { useEffect } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  Stack,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    document.title = "Daly Exercise+ | About";
  }, []);

  const instagramLink = () => {
    let url = "https://www.instagram.com/dalyexercise/";
    window.open(url, "_blank").focus();
  };

  const tiktokLink = () => {
    let url = "https://www.tiktok.com/@dalyexercise";
    window.open(url, "_blank").focus();
  };
  return (
    <Container className="full-page-length" fluid>
      <div className="white-body">
        <Container>
          <h1 className="pageTitle  title-margin">Welcome to Daly Exercise+</h1>
          <br />
          <div className="video-responsive highlight-border">
            <iframe
              // width="853"
              // height="480"
              src={`https://player.vimeo.com/video/995325856?h=f867e8130d`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
          <br />
          <br />
          <br />
          <h2 className="text-center p-3 h1">
            Add a <b className="headingColour">+</b> to your{" "}
            <b className="headingColour">P.E.</b>
          </h2>
          <div className="text-center h3 p-3">
            Daly Exercise+ makes teaching P.E. stress free, easy to follow and
            more importantly fun for you and your whole class!
          </div>
          <br />
          <Link to={`/pricing`} className="text-link">
            <div className="d-flex justify-content-center">
              <Button variant="primary" className="mainButton button-padding">
                View Pricing
              </Button>
            </div>
          </Link>
          <br />

          <br />
          <hr />
          <div>
            <br />
            <h1 className="pageTitle text-center ">Features</h1>
            <br />
            <h5 className="text-center">
              Daly Exercise+ can be accessed both through our iOS and Android
              mobile applications, and here on your browser.
            </h5>
            <br />
            <Row>
              <Col lg={3} sm={6} xs={12}>
                <Card className="feature-card-1">
                  <Card.Img
                    variant="top"
                    src="/icons/icon_graded.png"
                    className="feature-card-picture"
                  />
                  <Card.Body>
                    <Card.Text className="text-center h3">
                      Structured Yearly Plans
                    </Card.Text>
                    <Card.Text className="">
                      Structured lesson plans to help teach a fun and accessible
                      P.E. curriculum.
                      <br />
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} sm={6} xs={12}>
                <Card className="feature-card-2">
                  <Card.Img
                    variant="top"
                    src="/icons/icon_games.png"
                    className="feature-card-picture"
                  />
                  <Card.Body>
                    <Card.Text className="text-center h3">
                      Weekly Lessons
                      <br />
                      <br />
                    </Card.Text>
                    <Card.Text className="">
                      Teach your class using our curated structured lessons,
                      taken from our lesson plans!
                      <br />
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} sm={6} xs={12}>
                <Card className="feature-card-3">
                  <Card.Img
                    variant="top"
                    src="/icons/icon_schedule_tab.png"
                    className="feature-card-picture"
                  />
                  <Card.Body>
                    <Card.Text className="text-center h3">
                      Content Planning
                      <br />
                      <br />
                    </Card.Text>
                    <Card.Text className="">
                      Plan your P.E. in advance with the in-built schedule.
                      <br />
                      <br />
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} sm={6} xs={12}>
                <Card className="feature-card-4">
                  <Card.Img
                    variant="top"
                    src="/icons/icon_lessons_tab.png"
                    className="feature-card-picture"
                  />
                  <Card.Body>
                    <Card.Text className="text-center h3">
                      Videos and Interactive Resources
                    </Card.Text>
                    <Card.Text className="">
                      Over 300 videos are available on both web and mobile.
                      <br />
                      <br />
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              {/* NEW LINE  */}

              <Col lg={3} sm={6} xs={12}>
                <Card className="feature-card-5">
                  <Card.Img
                    variant="top"
                    src="/icons/icon_brainbreak_tab.png"
                    className="feature-card-picture"
                  />
                  <Card.Body>
                    <Card.Text className="text-center h3">
                      Well-Being Movement Breaks
                    </Card.Text>
                    <Card.Text className="">
                      Keep you student's minds active with our fun and
                      mindfulness movement breaks.
                      <br />
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} sm={6} xs={12}>
                <Card className="feature-card-6">
                  <Card.Img
                    variant="top"
                    src="/icons/icon_alerting.png"
                    className="feature-card-picture"
                  />
                  <Card.Body>
                    <Card.Text className="text-center h3">
                      Classroom Exercises
                      <br />
                      <br />
                    </Card.Text>
                    <Card.Text className="">
                      Take a break during class and keep your students active
                      with our classroom exercises.
                      <br />
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} sm={6} xs={12}>
                <Card className="feature-card-7">
                  <Card.Img
                    variant="top"
                    src="/icons/icon_classroom_tab.png"
                    className="feature-card-picture"
                  />
                  <Card.Body>
                    <Card.Text className="text-center h3">
                      Build Your
                      <br /> Classroom
                      <br />
                    </Card.Text>
                    <Card.Text className="">
                      Add your students to the classroom and let them choose
                      their own sporty animal!
                      <br />
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} sm={6} xs={12}>
                <Card className="feature-card-8">
                  <Card.Img
                    variant="top"
                    src="/icons/icon_home.png"
                    className="feature-card-picture"
                  />
                  <Card.Body>
                    <Card.Text className="text-center h3">
                      Exercise Homework
                      <br />
                      <br />
                    </Card.Text>
                    <Card.Text className="">
                      Assign your students exercises to do at home. Manage and
                      create custom homework. <br />
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <br />
          </div>

          <hr />
          <br />

          <Row>
            <Col md={2}></Col>
            <Col md={4}>
              <Card
                style={{ height: "200px" }}
                className="mb-3 p-3 grow instagram-store-card"
                onClick={() => instagramLink()}
              >
                <Stack direction="horizontal" className="my-auto">
                  <Stack className="my-auto text-center p-auto">
                    <div className=" h2">Checkout our</div>
                    <div className=" h2">Instagram!</div>
                  </Stack>

                  <img
                    src="/icons/icon_instagram.png"
                    style={{ width: "40%" }}
                  ></img>
                </Stack>
              </Card>
            </Col>
            {/* <Col md={2}></Col> */}
            <Col md={4}>
              <Card
                style={{ height: "200px" }}
                className="mb-3 p-3 grow tiktok-store-card"
                onClick={() => tiktokLink()}
              >
                <Stack direction="horizontal" className="my-auto">
                  <Stack className="my-auto text-center p-auto">
                    <div className=" h2">Checkout our</div>
                    <div className=" h2">Tiktok!</div>
                  </Stack>

                  <img
                    src="/icons/icon_tiktok.png"
                    style={{ width: "35%" }}
                  ></img>
                </Stack>
              </Card>
            </Col>
            <Col md={2}></Col>
          </Row>
          <br />

          <hr />
          <div>
            <br />
            <h1 className="pageTitle text-center ">Login / Sign Up Help</h1>

            <br />
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h5 className="pageTitle h3">
                    Setting up a Teacher Subscription
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li> Enter your Email Address & Password below </li>
                    <li>Enter your School's Details</li>
                    <li>Select 1 Subscription</li>
                    <li>Choose to pay Monthly or Yearly</li>
                    <li>Enter your Payment Details</li>
                    <li>Enjoy Daly Exercise+!</li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h5 className="pageTitle h3">
                    Setting up a School Subscription
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>Enter your Admin Email Address & Password Below</li>
                    <li>Enter your School's Details</li>
                    <li>Select the number of Teachers</li>
                    <li>Confirm your Email on Stripe</li>
                    <li>Add your teachers to your school.</li>
                    <li>Assign your teachers a license.</li>
                    <li>
                      Teacher's will Receive a Confirmation Email & Temporary
                      Password
                    </li>
                    <li>
                      Log in using that Email Address and Temporary Password
                    </li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <br />
            <Link to={`/register/teacher`} className="text-link">
              <div className="d-flex justify-content-center">
                <Button variant="primary" className="mainButton button-padding">
                  Sign Up
                </Button>
              </div>
            </Link>
          </div>
          <br />
        </Container>
      </div>
    </Container>
  );
};

export default About;
