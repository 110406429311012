import { useEffect, useState } from "react";
import { Card, Button, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const StructuredLessonListBox = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const [colour, setColour] = useState("");
  useEffect(() => {
    switch (props.lesson.strand) {
      // case "Athletics":
      //   setImageUrl("/icons/icon_run.png");
      //   setColour("#159A27");
      //   break;
      // case "Dance":
      //   setImageUrl("/icons/icon_dance.png");
      //   setColour("#36A9FB");
      //   break;
      // case "Gymnastics":
      //   setColour("#AC00BF");
      //   setImageUrl("/icons/icon_gymnastics.png");
      //   break;
      // case "Games":
      //   setColour("#B99417");
      //   setImageUrl("/icons/icon_games.png");
      //   break;
      // case "LogoIcon":
      //   setColour("#982D87");
      //   setImageUrl("/icons/low_res_logo_icon.png");
      //   break;
      // case "Warm Ups":
      //   setColour("#982D87");
      //   setImageUrl("/icons/low_res_logo_icon.png");
      //   break;
      // case "Non-Contact Games":
      //   setColour("#D40101");
      //   setImageUrl("/icons/icon_nc_games.png");
      //   break;
      // case "Active Break":
      //   setColour("#DA6CFB");
      //   setImageUrl("/icons/icon_alerting.png");
      //   break;
      // case "Mindfulness Break":
      //   setColour("#1FB3FF");
      //   setImageUrl("/icons/icon_calming.png");
      //   break;
      // case "TikTok Break":
      //   setColour("#982D87");
      //   setImageUrl("/icons/icon_tiktok.png");
      //   break;
      // case "Halloween":
      //   setColour("#404040");
      //   setImageUrl("/avatars/avatar27.png");
      //   break;
      // case "Christmas":
      //   setColour("#DA6CFB");
      //   setImageUrl("/icons/icon_christmas.png");
      //   break;
      // case "Christmas Break":
      //   setColour("#DA6CFB");
      //   setImageUrl("/icons/icon_christmas.png");
      case "Worksheets":
        setColour("#333");
        setImageUrl("/icons/icon_pencil.png");
        break;
      default:
        setImageUrl(`/icons/${props.lesson.icon}.png`);
        setColour(getColourFromName(props.lesson.colour));
        

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const getColourFromName = (name) => {
    switch (name) {
      case "TextPrimary":
        return "#404040";
      case "Green":
        return "#159A27"
      case "Blue":
        return "#36A9FB"
      case "Purple":
        return "#AC00BF"
      case "Yellow":
        return "#B99417"
      case "Red":
        return "#D40101"
      case "ActiveColour":
        return "#DA6CFB"
      case "CalmingColour":
        return "#1FB3FF"
      case "TikTokColour":
        return "#982D87"
      case "ChristmasColour":
        return "#DA6CFB"
      default:
        return "#AC00BF"
    }
  }
  return (
    <div
      className={`grow my-2 ${
        props.lesson.selected
          ? "selected-lesson-list"
          : "not-selected-lesson-list"
      }`}
      onClick={() => props.click()}
      style={
        props.lesson.selected
          ? { border: `1px solid ${colour}` }
          : { border: `1px solid grey` }
      }
    >
      <Stack direction="horizontal">
        <img src={imageUrl} style={{ height: "3rem", width: "3rem", margin:"0.5rem"}}></img>
        <div className="m-2 h5">{props.lesson.title}</div>
        <div
          style={{ backgroundColor: colour }}
          className={`ms-auto ${
            props.lesson.selected ? "selected-lesson" : ""
          }`}
        />
      </Stack>
    </div>
  );
};

export default StructuredLessonListBox;
