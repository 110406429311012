import Dashboard from "../../pages/Dashboard";
import Login from "../../pages/Login";
import RegisterDetailsPage from "../../pages/register-pages/RegisterDetailsPage";
import { useAuth } from "../../providers/UserProvider";

export const NoSchoolPage = ({ children }) => {
  const { hasSchool, loaded } = useAuth();

  if (!hasSchool && loaded) {
    return <RegisterDetailsPage />;
  }

  return children;
};
